<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col-md-12">
						<h5>
							<strong>{{ isNewRecord ? 'Nueva' : 'Detalle de' }} ODC {{ isNewRecord ? '' : ` #${purchaseOrder}` }}</strong>
						</h5>
						<a-tag color="red" v-if="actualPurchaseOrder.status == 0"> Por autorizar </a-tag>
						<a-tag color="orange" v-if="actualPurchaseOrder.status == 1"> Por pagar </a-tag>
						<a-tag color="blue" v-if="actualPurchaseOrder.status == 2"> Pagadas </a-tag>
						<a-tag color="green" v-if="actualPurchaseOrder.status == 3"> Facturadas </a-tag>
						<a-tag color="pink" v-if="actualPurchaseOrder.status == 4"> Validadas </a-tag>
						<a-tag color="pink" v-if="actualPurchaseOrder.status == 5"> Completadas </a-tag>
						<a-tag color="pink" v-if="actualPurchaseOrder.status == 6"> En sucursal </a-tag>
						<hr class="hrText m0" data-content="♦" />
					</div>
				</div>
				<purchaseOrders class="pt10" />
			</div>
		</div>
	</a-spin>
</template>

<script>
import { mapGetters } from 'vuex'
import purchaseOrders from '@/components/purchase-orders'
import utilities from '@/services/utilities'

export default {
	name: 'DetailPurchaseOrder',
	components: {
		purchaseOrders,
	},
	props: {},
	data() {
		return {}
	},
	computed: {
		...mapGetters('purchaseOrders', ['spinnerLoaderLabel', 'spinnerLoader', 'actualPurchaseOrder']),
		isNewRecord() {
			return !!!utilities.objectValidate(this.$route, 'params.id', false)
		},
		purchaseOrder() {
			return this.isNewRecord ? '' : this.$route.params.id
		},
	},
	beforeCreate() {},
	created() {},
	beforeMount() {},
	mounted() {},
	beforeUpdate() {},
	updated() {},
	beforeDestroy() {},
	destroyed() {},
	methods: {},
	watch: {},
}
</script>